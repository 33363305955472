export default [
  {
    title: 'Tanggal Registrasi',
    dataIndex: 'registration_at2',
  },
  {
    title: 'Kode Gudang',
    dataIndex: 'code',
    slots: { customRender: 'kodegudang' },
    sorter: (a, b) => a.id - b.id,
  },
  {
    title: 'Nama Gudang',
    dataIndex: 'name',
  },
  {
    title: 'Tipe Gudang',
    dataIndex: 'type_name',
  },
  {
    title: 'Alamat Gudang',
    dataIndex: 'address',
  },
  {
    title: 'Kode Distributor',
    dataIndex: 'distributor_code',
    slots: { customRender: 'kodedistributor' },
    sorter: (a, b) => a.id - b.id,
  },
  {
    title: 'Nama Distributor',
    dataIndex: 'distributor',
    // sorter: (a, b) => a.customer.length - b.customer.length,
    // slots: {
    //   filterDropdown: 'filterDropdown',
    //   filterIcon: 'filterIcon',
    //   customRender: 'customer',
    // },
    // onFilter: (value, record) => record.customer.toLowerCase().includes(value.toLowerCase()),
  },
  {
    title: 'Region Gudang',
    dataIndex: 'region',
  },
  {
    title: 'Provinsi Gudang',
    dataIndex: 'provinsi',
  },
  // TODO:
  // Region Gudang
  //  Provinsi Gudang
  {
    title: 'Area Gudang',
    dataIndex: 'area',
  },
  {
    title: 'Kabupaten / Kota',
    dataIndex: 'wilayah',
  },
  {
    title: 'Kapasitas Gudang',
    dataIndex: 'kapasitas',
  },
  {
    title: 'Kapasitas Gudang Max',
    dataIndex: 'kapasitas_max',
  },
  {
    title: 'Kapasitas Bongkar Muat',
    dataIndex: 'kapasitas_bongkarmuat',
  },
  {
    title: 'Metode Bongkar Muat',
    dataIndex: 'metode_bongkar',
  },
  {
    title: 'SSM',
    dataIndex: 'user_ssm',
  },
  {
    title: 'ASM',
    dataIndex: 'user_sm',
  },
  {
    title: 'TSO',
    dataIndex: 'user_am',
  },
  {
    title: 'Status',
    dataIndex: 'status_text',
    slots: { customRender: 'status' },
  },
  {
    title: 'Tanggal Nonaktif',
    dataIndex: 'status_date',
  },
  {
    title: 'Action',
    slots: { customRender: 'action' },
  },
]
